import { ChronoUnit, Instant, LocalDate, nativeJs } from "@js-joda/core";
import { configureScope, getCurrentHub } from "@sentry/nextjs";
import LogRocket from "logrocket";
import { SupportCaseStateItem } from "../frontend/state/SupportCaseItemState";
import { BillbeeOrderState } from "../model/Order";
import { ReturnItemEmail } from "../model/ReturnItem";
import { SupportCaseStatus } from "../model/SupportCase";
import { IS_APP_ENV_PRODUCTION } from "./constantsPublic";

// Partial pick results in picked keys being optional
export type PartialPick<T, K extends keyof T> = {
  [P in K]?: T[P];
};

export const gql = String.raw;

export function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(" ");
}

export async function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function isNotUndefined<T>(value: T | undefined): value is T {
  return value !== undefined;
}

export function getCookie(cname: string): string {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function getNiceStatusForInternal(status: SupportCaseStatus): string {
  const niceStatus =
    status === SupportCaseStatus.Editing
      ? "Kunde bearbeitet"
      : status === SupportCaseStatus.Submitting
      ? "Kunde bearbeitet"
      : status === SupportCaseStatus.Validating
      ? "CC bearbeitet"
      : status === SupportCaseStatus.Shipping
      ? "Retoure"
      : status === "REJECTED"
      ? "CC abgewiesen"
      : SupportCaseStatus.Completed && "abgeschlossen";
  return niceStatus;
}

export function parseArrayToQueryParams(key: string, valueArray: (string | number)[]): string {
  return valueArray
    .reduce((total, item, i, arr) => {
      const res = `${total}${key}=${item}${i < arr.length - 1 ? "&" : ""}`;
      return res;
    }, "")
    .toString();
}

export function cleanUpOrderNumberInputParams(str?: string): string {
  return str
    ? str
        .replace(/\s/g, "")
        .replace("#", "")
        .replace(/-Shipping-(\d*)/g, "")
        .replace(/-shipping-(\d*)/g, "")
        .replace(/-S-(\d*)/g, "")
        .replace(/-s-(\d*)/g, "")
        .toUpperCase()
    : "";
}

export function isOrderCancelable(status: BillbeeOrderState): boolean {
  return (
    status === BillbeeOrderState.Bestaetigt ||
    status === BillbeeOrderState.Bestellt ||
    status === BillbeeOrderState.Zahlung_erhalten
  );
}

export function isOrderTrackable(status: BillbeeOrderState): boolean {
  return (
    status === BillbeeOrderState.Gepackt ||
    status === BillbeeOrderState.Im_Fulfillment ||
    status === BillbeeOrderState.Versendet
  );
}

export function getCleanEmail(email: string): string {
  const clean = email.trim().toLowerCase().replace("@googlemail.com", "@gmail.com");
  return clean;
}

export function getCleanOrdernumber(no: string): string {
  const clean = no.trim().toUpperCase();
  return clean;
}

// depcreated, do not use LocalDate, use Instant
export function jsDateToLocalDate(dt: Date): LocalDate {
  return LocalDate.of(dt.getFullYear(), dt.getMonth() + 1, dt.getDate());
}

export function jsDateToInstant(dt: Date): Instant {
  return Instant.from(nativeJs(dt));
}

// Wenn wir uns zum Zeitpunkt des returns in der GracePeriod befinden,
// also zwischen 24. und 10. dann wird ein return ermöglicht
// mit einem Rechnungsdatum beginnend ab 3 Wochen vor dem 24.ten,
// also ab dem 3.12. Dass heißt im extremsten Fall kann am 10.1.
// noch eine Bestellung vom 3.12 retourniert werden.
// Servicefälle ausserhalb der GracePeriod,
// haben immer die 3 Wochen beginnend ab Rechnungsdatum.
export function isReturnDateOk(invoiceDate: LocalDate, now = LocalDate.now()): boolean {
  const returnPeriodInDays = 7 * 3;
  // time frame for christmas starts on 12/24/2022 and ends on 1/10/2023
  // invoiceDates beginning on the 24th - 21 days will be granted grace return
  const gracePeriod: { from: LocalDate; start: LocalDate; end: LocalDate } = {
    from: LocalDate.of(2023, 12, 24).minusDays(returnPeriodInDays),
    start: LocalDate.of(2023, 12, 24),
    end: LocalDate.of(2024, 1, 10),
  };
  if (now.isAfter(gracePeriod.start) && now.isBefore(gracePeriod.end)) {
    return invoiceDate.isAfter(gracePeriod.from);
    // default 21 days from invoiceDate
  } else {
    return invoiceDate.plus(returnPeriodInDays, ChronoUnit.DAYS).isAfter(now);
  }
}

export function isOrderOutOfWarranty(dt: Date): boolean {
  return (
    jsDateToInstant(dt)
      // 3 days tolerance
      .plus(2 * 365 + 3, ChronoUnit.DAYS)
      .isBefore(Instant.now())
  );
}

export const isBrowserContext = (): boolean => typeof window !== "undefined";

export function initTrackersContextAware(): void {
  if (!isBrowserContext()) return;
  if (!IS_APP_ENV_PRODUCTION) return;

  if (getCookie("cookie_notice_accepted") === "true") {
    LogRocket.init("m3u3w6/serviceportal");
    LogRocket.getSessionURL((sessionURL) => {
      configureScope((scope) => {
        scope.setExtra("sessionURL", sessionURL);
      });
    });
  }
  if (getCookie("cookie_notice_accepted") !== "true") {
    const client = getCurrentHub().getClient();
    if (client) client.getOptions().enabled = false;
  }
}

export function checkSpecialSpeditionCase(name = "", item: SupportCaseStateItem | ReturnItemEmail): boolean {
  return name !== "DHL Spedition" || Boolean(name === "DHL Spedition" && item.isFirstDhlSpeditionsItem);
}
